import { IModel, IRuntimeTypeInformation, Id, LocalDate, LocalMonth, Model } from "@lib/index"
import { Staat } from "@generated/de/lohn24/model/staat/Staat"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Lohnabrechnung } from "@generated/de/lohn24/model/lohnabrechnung/Lohnabrechnung"

export interface IArbeitnehmer extends IModel {
	geburtsland: string | null
	land: string | null
	lohnabrechnung: string
	mandant: string
	version: number
	austritt: LocalDate | null
	eintritt: LocalDate
	ersteintritt: LocalDate | null
	geburtsdatum: LocalDate | null
	zeitraum: string
	anrede: string | null
	anschriftenzusatz: string | null
	arbeitnehmernummer: string
	beschaeftigungsbezeichnung: string | null
	beschaeftigungsnummer: string
	bic: string | null
	geburtsname: string | null
	geburtsort: string | null
	iban: string | null
	kontoinhaber: string | null
	nachname: string | null
	namensvorsatz: string | null
	namenszusatz: string | null
	ort: string | null
	postleitzahl: string | null
	sozialversicherungsnummer: string | null
	steueridentifikationsnummer: string | null
	strasse: string | null
	titel: string | null
	vorname: string | null
}

export class Arbeitnehmer extends Model<Arbeitnehmer> {
	public geburtsland!: Id<Staat> | null
	public land!: Id<Staat> | null
	public lohnabrechnung!: Id<Lohnabrechnung>
	public mandant!: Id<Mandant>
	public version!: number
	public austritt!: LocalDate | null
	public eintritt!: LocalDate
	public ersteintritt!: LocalDate | null
	public geburtsdatum!: LocalDate | null
	public zeitraum!: LocalMonth
	public anrede!: string | null
	public anschriftenzusatz!: string | null
	public arbeitnehmernummer!: string
	public beschaeftigungsbezeichnung!: string | null
	public beschaeftigungsnummer!: string
	public bic!: string | null
	public geburtsname!: string | null
	public geburtsort!: string | null
	public iban!: string | null
	public kontoinhaber!: string | null
	public nachname!: string | null
	public namensvorsatz!: string | null
	public namenszusatz!: string | null
	public ort!: string | null
	public postleitzahl!: string | null
	public sozialversicherungsnummer!: string | null
	public steueridentifikationsnummer!: string | null
	public strasse!: string | null
	public titel!: string | null
	public vorname!: string | null

	static _types: Record<keyof IArbeitnehmer, IRuntimeTypeInformation> = {
		id: { type: Id },
		geburtsland: { type: Id, nullable: true },
		land: { type: Id, nullable: true },
		lohnabrechnung: { type: Id },
		mandant: { type: Id },
		version: { type: Number },
		austritt: { type: LocalDate, nullable: true },
		eintritt: { type: LocalDate },
		ersteintritt: { type: LocalDate, nullable: true },
		geburtsdatum: { type: LocalDate, nullable: true },
		zeitraum: { type: LocalMonth },
		anrede: { type: String, nullable: true },
		anschriftenzusatz: { type: String, nullable: true },
		arbeitnehmernummer: { type: String },
		beschaeftigungsbezeichnung: { type: String, nullable: true },
		beschaeftigungsnummer: { type: String },
		bic: { type: String, nullable: true },
		geburtsname: { type: String, nullable: true },
		geburtsort: { type: String, nullable: true },
		iban: { type: String, nullable: true },
		kontoinhaber: { type: String, nullable: true },
		nachname: { type: String, nullable: true },
		namensvorsatz: { type: String, nullable: true },
		namenszusatz: { type: String, nullable: true },
		ort: { type: String, nullable: true },
		postleitzahl: { type: String, nullable: true },
		sozialversicherungsnummer: { type: String, nullable: true },
		steueridentifikationsnummer: { type: String, nullable: true },
		strasse: { type: String, nullable: true },
		titel: { type: String, nullable: true },
		vorname: { type: String, nullable: true },
	}

	static props: Record<keyof IArbeitnehmer, string> = {
		id: "id",
		geburtsland: "geburtsland",
		land: "land",
		lohnabrechnung: "lohnabrechnung",
		mandant: "mandant",
		version: "version",
		austritt: "austritt",
		eintritt: "eintritt",
		ersteintritt: "ersteintritt",
		geburtsdatum: "geburtsdatum",
		zeitraum: "zeitraum",
		anrede: "anrede",
		anschriftenzusatz: "anschriftenzusatz",
		arbeitnehmernummer: "arbeitnehmernummer",
		beschaeftigungsbezeichnung: "beschaeftigungsbezeichnung",
		beschaeftigungsnummer: "beschaeftigungsnummer",
		bic: "bic",
		geburtsname: "geburtsname",
		geburtsort: "geburtsort",
		iban: "iban",
		kontoinhaber: "kontoinhaber",
		nachname: "nachname",
		namensvorsatz: "namensvorsatz",
		namenszusatz: "namenszusatz",
		ort: "ort",
		postleitzahl: "postleitzahl",
		sozialversicherungsnummer: "sozialversicherungsnummer",
		steueridentifikationsnummer: "steueridentifikationsnummer",
		strasse: "strasse",
		titel: "titel",
		vorname: "vorname",
	}

	constructor(_json: IArbeitnehmer) {
		super(_json)
	}
}
