import { IRuntimeTypeInformation, Serializable } from "@lib/index"
import { LohnabrechnungStatus } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungStatus"

export interface ILohnabrechnungVersionszustand {
	version: number
	abrechnungsstatus: LohnabrechnungStatus
	beschaeftigungsnummer: string
}

export class LohnabrechnungVersionszustand extends Serializable<LohnabrechnungVersionszustand> {
	public version!: number
	public abrechnungsstatus!: LohnabrechnungStatus
	public beschaeftigungsnummer!: string

	static _types: Record<keyof ILohnabrechnungVersionszustand, IRuntimeTypeInformation> = {
		version: { type: Number },
		abrechnungsstatus: { type: LohnabrechnungStatus },
		beschaeftigungsnummer: { type: String },
	}

	static props: Record<keyof ILohnabrechnungVersionszustand, string> = {
		version: "version",
		abrechnungsstatus: "abrechnungsstatus",
		beschaeftigungsnummer: "beschaeftigungsnummer",
	}

	constructor(_json: ILohnabrechnungVersionszustand) {
		super(_json)
	}
}
